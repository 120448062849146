import React from "react";
import { ClerkLoaded, ClerkLoading, SignIn } from "@clerk/nextjs";
import Loading from "../../components/Loading";
import Meta from "../../components/Meta";

const Login = () => {
    return (
        <>
            <div className="mt-32 px-8 md:px-4">
                <Meta title='Secure Sign In | NetZylo' description='Log in securely to access your account. Enjoy a seamless and safe user experience with our sign-in process.' />
                <div className="border gradient-box dark:bg-jacarta-900 bg-white  max-w-[500px] ">
                    <div className="min-h-[400px] relative w-full h-full">
                        <ClerkLoading>
                            <Loading />
                        </ClerkLoading>

                        <ClerkLoaded>
                            {/* <h5 className=" z-[999] text-center top-[20px] absolute text-2xl font-bold w-full bg-white dark:text-white text-jacarta-900 dark:bg-jacarta-900 p-4" id="walletModalLabel">
                                Login                 
                             </h5> */}
                             
                            <div className="relative">
                                <SignIn appearance={{
                                    elements: {
                                        headerTitle: 'text-center mb-8 text-2xl font-semibold dark:text-white text-jacarta-900',
                                        headerSubtitle: 'hidden',
                                        dividerLine: 'dark:bg-gray-200',
                                        dividerText: 'dark:text-gray-200  text-gray-300',
                                        formButtonPrimary: 'text-center justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[18px] px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 w-full block md:inline my-4 capitalize',
                                        formFieldInput: 'contact-form-input dark:bg-jacarta-900  hover:ring-accent/10 focus:ring-accent border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-6 hover:ring-2 dark:text-white',
                                        rootBox: '!w-full max-w-full',
                                        card: 'm-0 border-none rounded-md !w-full max-w-full dark:bg-jacarta-900 bg-white',
                                        otpCodeFieldInput: 'contact-form-input dark:bg-jacarta-900  hover:ring-accent/10 focus:ring-accent border-jacarta-600 border-[2px] dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-6 hover:ring-2 dark:text-white rounded-none',
                                        socialButtonsIconButton: 'dark:border-gray-200 bg-accent/25 ',
                                        // socialButtonsIconButton: 'dark:border-gray-200 bg-accent/25 ',
                                        // socialButtonsBlockButton__apple: 'filter-invert-1 !dark:filter-invert-0 ',

                                    }
                                }}
                                    afterSignInUrl='/profile'
                                    signUpUrl="/signup"
                                />
                                <div className="absolute right-0 top-4">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            window.location.href = "/";
                                        }}
                                    
                                            
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                                        >
                                            <path fill="none" d="M0 0h24v24H0z" />
                                            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                        </ClerkLoaded>

                    </div>
                </div >
            </div>
        </>
    )
}

export default Login